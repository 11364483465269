import { useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import { FILTERING_TRANSITION_DURATION } from "../../../../config/constants";
import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import { ShareIcon } from "../../../client/assets/icons/insuranceranked/ShareIcon";
import {
	getAwardText,
	getIsNotUserFiltered,
	getIsTargetedPath,
} from "../../../selectors";
import { BaseBenefitsList } from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import { BaseCompanyName } from "../../BaseComponents/BaseCompanyName";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import { BaseLinkWrapper } from "../../BaseComponents/BaseLinkWrapper";
import { BaseReviewLink } from "../../BaseComponents/BaseReviewLink";
import { BaseReviewLinkWrapper } from "../../BaseComponents/BaseReviewLinkWrapper";
import { BaseServiceFlag } from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseTrustpilotReview } from "../../BaseComponents/BaseTrustpilotReview";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import { CompanyContext } from "../../CompanyList/CompanyContext";
import { AmBestRating } from "./AmBestRating";
import { getStyles } from "./styles";

export const LifeInsuranceCompany = () => {
	const { companyIndex, company, isFeaturedCompany } =
		useContext(CompanyContext);
	const awardText = useSelector((state) => getAwardText(state, { company }));
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeaturedCompany });
	const nodeRef = useRef(null);
	const isTargetedPath = useSelector(getIsTargetedPath);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div
				className={`tw-relative tw-mx-auto tw-block tw-max-w-[400px] tw-overflow-hidden tw-rounded-[3px] tw-border tw-border-solid tw-bg-white tw-p-4 tw-transition-all hover:tw-shadow-[0_0px_40px_0px_rgba(0,0,0,0.18)] sm:tw-max-w-[500px] md:tw-max-w-[600px] lg:-tw-mx-4 lg:tw-max-w-none ${
					isFeaturedCompany
						? "tw-mt-6 tw-border-[#2299DC] lg:tw-mt-12"
						: "tw-mb-5 tw-border-[#e2e8f0] hover:tw-border-[#2299DC] lg:tw-mb-6 lg:tw-py-6"
				}`}
			>
				<BaseReviewLinkWrapper className="tw-w-full">
					<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				</BaseReviewLinkWrapper>
				<div
					className={`${isFeaturedCompany ? "tw-pt-4 lg:tw-pb-2 lg:tw-pt-6" : ""}`}
				>
					<div
						className={`tw-relative tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-gap-0 ${
							isFeaturedCompany ? "tw-px-4" : ""
						}`}
					>
						<BaseServiceFlag classNames={styles.serviceFlag} />

						<BaseReviewLinkWrapper
							className={`tw-flex tw-shrink-0 tw-items-center tw-justify-center lg:tw-w-[284px] lg:tw-pr-3 ${awardText ? "tw-pt-8 lg:tw-pt-10" : "tw-pt-4 lg:tw-pt-0"}`}
						>
							<div className="tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-justify-around tw-gap-4">
								<BaseCompanyLogo
									classNames={styles.companyLogo}
									width={160}
									height={160}
								/>
								<BaseTrustpilotReview classNames={styles.trustpilotReview} />
							</div>
						</BaseReviewLinkWrapper>

						<BaseReviewLinkWrapper className="tw-flex tw-grow tw-flex-col lg:tw-max-w-none lg:tw-px-3">
							<div className="tw-flex tw-w-full tw-flex-col">
								<div className="tw-flex tw-items-center tw-gap-2">
									<BaseCompanyName className="tw-text-balance tw-text-xl tw-font-bold tw-leading-7 tw-text-[#000A25]" />
									<AmBestRating />
								</div>
								<BaseBenefitsList
									{...styles.benefitsList}
									icon={
										<CheckIcon className="tw-mt-[5px] tw-text-base tw-text-[#2299DC]" />
									}
								/>
							</div>
						</BaseReviewLinkWrapper>

						<div className="tw-flex tw-shrink-0 lg:tw-w-[192px] lg:tw-pl-3">
							<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
								<BaseReviewLinkWrapper className="tw-flex tw-w-full tw-items-center tw-justify-center">
									{companyIndex === 1 && (
										<BaseSocialProof {...styles.socialProof} />
									)}
								</BaseReviewLinkWrapper>
								<BaseLinkWrapper className="tw-w-full">
									<BaseVisitLink {...styles.visitLink} icon={<ShareIcon />}>
										View Plans
									</BaseVisitLink>
								</BaseLinkWrapper>
								{isTargetedPath ? (
									<BaseLinkWrapper className="tw-w-full">
										<BaseVisitLink
											{...{
												wrapper: styles.reviewLink.wrapper,
												contentWrapper: styles.reviewLink.text,
											}}
										>
											100% Free Quote
										</BaseVisitLink>
									</BaseLinkWrapper>
								) : (
									<BaseReviewLink
										classNames={styles.reviewLink}
										linkText={"Read Review"}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	);
};
